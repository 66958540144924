/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FaFacebookF, FaRegEnvelope } from "react-icons/fa"
import logo from "../img/logo.png"
import "tachyons"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          siteTitle: title
          mailChimpUrl
          pinterest
          facebook
          twitter
        }
      }
    }
  `)

  return (
    <>
      <footer className="pa2 bg-dark-gray near-white pv2">
        <div className="flex flex-wrap justify-around w-100 mw9 center mv4">
          <div className="w-100 mw5 mb4">
            <span className="display f2">
              <img className={"w-50"} src={logo} alt="..." />
            </span>
            <hr />
            <div className="w-100 flex justify-around items-center pv2">
              {data?.site.siteMetadata.facebook && (
                <a
                  className="near-white"
                  href={
                    "http://www.facebook.com/" +
                    data?.site?.siteMetadata?.facebook
                  }
                >
                  <FaFacebookF />
                </a>
              )}

              <a
                className="near-white"
                href="mailto:lindavanderwal@quicknet.nl"
              >
                <FaRegEnvelope />
              </a>
            </div>
          </div>
          <div className="flex flex-column">
            <span className="near-white sans-serif f3 ttu tracked mb3 db">
              {data?.site?.siteMetadata?.siteTitle}
            </span>
            <Link
              to="/blog"
              className="near-white sans-serif f5 tracked pv1 db"
            >
              ALLE ITEMS
            </Link>
            <Link
              to="/rss.xml"
              className="near-white sans-serif f5 tracked pv1 db"
            >
              RSS FEED
            </Link>
          </div>
          <div className="flex flex-column">
            <span className="near-white sans-serif f3 tracked mb3 db">
              MEER INFO
            </span>
            <Link
              to="/about"
              className="near-white sans-serif f5 tracked pv1 db"
            >
              ABOUT US
            </Link>
            <Link
              to="/algemene-voorwaarden"
              className="near-white sans-serif f5 tracked pv1 db"
            >
              ALGEMENE
              <br />
              VOORWAARDEN
            </Link>
            <a
              href={data?.site?.siteMetadata?.mailChimpUrl}
              className="near-white sans-serif f5 tracked pv1 db"
            >
              Nieuwsbrief
            </a>
          </div>
        </div>
        <div className="w-100 mw9 center silver mb3">
          <div className="w-100 bb b--mid-gray mv3" />
          <div className="flex w-100 mw6 items-center justify-center justify-start-ns">
            <a
              href="/sitemap.xml"
              className="silver sans-serif f5 tracked pv1 db mh1"
            >
              SITEMAP
            </a>
            <span className="mh1">|</span>
            <Link
              to="/privacy"
              className="silver sans-serif f5 tracked pv1 db mh1"
            >
              PRIVACY
            </Link>
            <span className="mh1">|</span>
          </div>
        </div>
        <div className="w-100 mw9 silver center sans-serif f6">
          <p>
            &copy; Copyright Flex &amp; Relax {new Date().getFullYear()}. Alle
            rechten voorbehouden.
          </p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
