/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"
import Header from "./header.js"
import Footer from "./footer.js"
import "tachyons"
import "./styles/custom.tachyons.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <body className="bg-near-white mid-gray" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  )
}

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// }

export default Layout
